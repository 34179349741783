.nav-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(19, 19, 19);
  border-radius: 8px;
  font-size: small;
  margin-top: 3px;
  width: 5em;
  height: 5em;
  color: white;
  border: 1px solid white;
}

.nav-tab:hover {
  background-color: aliceblue;
  color: black;
  border: 1px solid black;
}

.nav-tab-completed {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0.75;
  background-color: lightgrey;
  border-radius: 8px;
  margin-top: 3px;
  font-size: small;
  width: 5em;
  height: 5em;
  border: 1px solid rgb(60, 0, 170);
}
