.witness-container {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  margin: 2%;
  padding: 1vh;
  background-color: #c0c1c2;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 10px 10px 10px rgb(54, 15, 15);
}

.remove-witness-btn {
  background-color: rgb(209, 83, 83);
  color: white;
  transition: background-color 0.25s ease-in-out;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgb(224, 212, 212);
  max-height: 4em;
}

.remove-witness-btn:hover {
  background-color: rgb(173, 0, 35);
  color: black;
}

.witness-container:has(.remove-witness-btn:hover) {
  background-color: #e3e7e9;
  color: black;
}

.add-witness-button {
  width: 10em;
  height: 3em;
  font-weight: bolder;
  text-align: center;
  border-radius: 8px;
  margin-top: 1em;
}
