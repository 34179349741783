.person-container {
  display: flex;
  justify-content: space-between;
  margin: 2px;
  padding-left: 10%;
  border-radius: 8px;
  background-color: beige;
  color: black;
}

.person-container button {
  margin: 2%;
  border-radius: 8px;
  background-color: brown;
  color: white;
}
