.general-info-container {
  background-color: #c7d8e4;
  text-align: left;
  color: black;
  border-radius: 8px;
  max-width: 800px;
  margin: 1%;
  padding: 5%;
  width: 85%;
  box-shadow: 10px 10px 15px rgba(253, 243, 243, 0.1);
}
.general-info-container h1,
h2 {
  border-bottom: 1px solid black;
}

.general-info-container label {
  color: black;
  font-size: 14px;
  display: block;
  margin-bottom: 8px;
}

.general-info-container input[type="text"],
input[type="email"] {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}

.general-info-container textarea {
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
  resize: none;
}

.general-info-container input[type="text"]:focus,
input[type="email"]:focus {
  border-color: #064670;
  outline: none;
  box-shadow: 0px 0px 8px rgba(255, 132, 50, 0.5);
}

.general-info-container textarea:focus {
  border-color: #064670;
  outline: none;
  box-shadow: 0px 0px 8px rgba(255, 132, 50, 0.5);
}

#safetyTeamInput {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}

#safetyTeamInput:focus {
  border-color: #064670;
  outline: none;
  box-shadow: 0px 0px 8px rgba(255, 132, 50, 0.5);
}

.violationTypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.check-container {
  display: flex;
  justify-content: right;
  min-width: 20vw;
}
