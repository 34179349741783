.get-report-container {
  background-color: rgb(56, 52, 52);
  color: white;
  border-radius: 8px;
  margin: 2%;
  padding: 5%;
}

.get-report-container label {
  font-size: 14px;
  display: block;
  margin-bottom: 2px;
  color: white;
}

.get-report-container input[type="text"] {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}

.get-report-container input[type="text"]:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0px 0px 8px rgba(52, 152, 219, 0.5);
}
