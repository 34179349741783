.type-button {
  display: inline-block;
  margin: 2% 5%;
  font-size: 14px;
  color: white;
  background-color: rgb(56, 52, 52);
  padding: 5%;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.1s ease;
  transition: color 0.1s ease-in;
  transition: font-weight 0.1s ease-in;
  min-height: 4em;
  width: 65%;
  text-align: center;
  border: 1px solid white;
}

.type-button:hover {
  background-color: whitesmoke;
  color: #333;
  border: 1px solid black;
}
