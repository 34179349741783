.ppe-container {
  display: block;
  background-color: #c7d8e4;
  color: black;
  border-radius: 8px;
}

.ppe-unit-selected {
  display: block;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: rgb(14, 7, 0);
  color: white;
  text-align: center;
  border: 1px solid rgb(255, 0, 0);
  padding: 1%;
  font-weight: bold;
  min-height: 10vh;
  width: 95%;
  margin: 1vh;
  border-radius: 8px;
}

.ppe-unit {
  display: block;
  justify-content: space-evenly;
  background-color: rgb(241, 209, 158);
  text-align: center;
  border: 1px solid #001f35;
  padding: 1%;
  font-weight: bold;
  min-height: 5vh;
  width: 55%;
  margin: 1vh;
  border-radius: 8px;
}
