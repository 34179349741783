.people-form {
  background-color: #c7d8e4;
  text-align: left;
  color: black;
  border-radius: 8px;
  max-width: 800px;
  margin: 2%;
  padding: 5%;
  width: 85%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.people-form h1 {
  border-bottom: 1px solid white;
}

.people-form label {
  color: black;
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
}

.people-form input[type="text"] {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  transition: border-color 0.3s ease-in-out;
}

.people-form input[type="text"]:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0px 0px 8px rgba(52, 152, 219, 0.5);
}

.people-form-container {
  display: flex;
  justify-content: center;
  padding: 5%;
  border-radius: 8px;
  background-color: #15191b;
  color: white;
}

.add-employee-form {
  background-color: #090a0a;
  text-align: left;
  border-radius: 8px;
  max-width: 800px;
  margin: 2%;
  padding: 5%;
  width: 85%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.add-employee-form label {
  color: white;
}

.witnesses-container {
  display: flex;
  flex-wrap: wrap;
}
