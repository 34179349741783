.home-container {
  background-color: #c7d8e4;
  text-align: center;
  color: black;
  max-width: 600px;
  margin: 2%;
  padding: 5%;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(192, 190, 190, 0.1);
}

.home-decision {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.new-report-types {
  grid-column: 1;
  display: block;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.existing-report {
  grid-column: 2;
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.type-button-container {
  transition: visibilty 1s ease-in-out;
}

.new-existing-btn {
  height: 4em;
  width: 85%;
  border-radius: 8px;
  font-size: large;
}

.existing-btn {
  border-radius: 6px;
  width: 50%;
}

.new-existing-btn:hover {
  background-color: black;
  color: white;
  box-shadow: 0px 1px 2px rgba(216, 207, 207, 0.1);
}
