.main-container {
  background-color: rgb(56, 56, 56);
  text-align: center;
  display: grid;
  grid-template-rows: 15vh auto 1fr;
}

.header {
  grid-row: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #c7d8e4;
  margin: 3em 0.25em 0 0.25em;
  gap: 1%;
  padding: 2%;
  border-radius: 5px;
  border: 1px solid rgb(199, 176, 176);
  mask-border-mode: luminance;
}

a {
  text-decoration: none;
  color: black;
}

.nav-bar {
  display: flex;
  flex-wrap: wrap;
  background-color: #c7d8e4;
  gap: 1%;
  mask-border-mode: luminance;
}

.nav-bar a {
  color: black;
}

.pages-container {
  grid-row: 2;
  display: flex;
  justify-content: center;
  min-height: 800px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #c7d8e4;
  grid-row: 3;
  margin: 0.25em;
  padding: 2%;
  border-radius: 5px;
  mask-border-mode: luminance;
}

.footer a {
  color: black;
}

.description-textarea {
  width: 75%;
}

.save-back-button {
  width: 10em;
  height: 3em;
  font-weight: bolder;
  text-align: center;
  border-radius: 8px;
}
.spanish-button {
  height: 3em;
  font-weight: bolder;
  text-align: center;
  border-radius: 8px;
}

.save-back-button:hover {
  background-color: #ad5e04;
  color: white;
}

.save-back-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5em;
  margin-top: 5em;
  background-color: rgb(39, 37, 37);
  border-radius: 8px;
}

.radio-container {
  display: flex;
  gap: 2em;
}

.optional-field-hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms ease-in-out, visibility 100ms ease-in-out;
}

.optional-field-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 500ms ease-in-out, visibility 100ms ease-in-out;
}

.summary-container {
  background-color: rgb(255, 254, 254);
  border: 1px solid black;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid black;
  padding: 2px;
}

tr:nth-child(even) {
  background-color: lightgray;
}

.confirm-button {
  height: 6em;
  width: 50%;
  background-color: rgb(80, 119, 80);
  margin-top: 2em;
  color: white;
}

.confirm-button:hover {
  background-color: red;
}

.missing-section {
  background-color: red;
  padding: 1%;
  margin: 0 5%;
  height: 4em;
  border-radius: 8px;
}

.injury-info-container {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;
}

.signature-container {
  display: grid;
  background-color: gainsboro;
  width: 50%;
  border-radius: 8px;
}

.sig-canvas {
  width: 99.9%;
  border-radius: 8px;
}

.spinner-container {
  display: flex;
  justify-content: center;
}

.spinner {
  justify-self: center;
  background-image: url("../Resources/Loading3.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 8em;
  width: 8em;
  margin-top: 25%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  animation: spin 1s ease-in-out infinite;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  table-layout: fixed;
}

td:nth-child(1) {
  width: 33%;
  font-weight: 600;
}

td:nth-child(2) {
  font-weight: 400;
}

.PCIImage {
  width: 5em;
  height: 5em;
  background-image: url("../Resources/PCIImage.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.info-line {
  display: flex;
  justify-content: space-between;
}

.login-container {
  display: flex;
  flex-direction: row;
  gap: 5%;
}
