.images-container {
  display: flex;
}

.photo-upload-container {
  background-color: rgb(0, 0, 0);
  display: flex;
  min-height: 150px;
  justify-content: space-evenly;
  border-radius: 8px;
  border: 2px solid rgb(253, 250, 218);
  mask-border-mode: luminance;
  align-items: center;
  margin: 5% 0;
}

.preview-container {
  display: grid;
  padding: 2%;
  grid-template-rows: auto auto auto;
}
.preview-container img {
  width: 95%;
  margin-top: 1%;
  justify-self: center;
  object-fit: contain;
}
.preview-container textarea {
  width: 75%;
  margin: 3%;
  justify-self: center;
}

.shutter-button {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 6em;
  width: 6em;
  border-radius: 40%;
  padding: 5%;
  background-color: rgb(201, 43, 15);
  border: 2px solid rgb(75, 12, 12);
  color: rgb(253, 250, 218);
}

.switch-view-button {
  position: absolute;
  bottom: 15%;
  left: 25%;
  height: 6em;
  width: 6em;
  border-radius: 50%;
  background-color: rgb(47, 90, 26);
  border: 1px solid silver;
  color: silver;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: center;
  gap: 2%;
  grid-template-rows: auto;
  margin-left: 2em;
  margin-right: 2em;
}

.image-thumbnail {
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: appear 500ms ease-in-out;
}

.image-thumbnail:hover {
  border: 2px solid red;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.image-edit-button {
  position: absolute;
  right: 15%;
  height: 2em;
}

.image {
  max-width: 100px;
  max-height: 100px;
}
